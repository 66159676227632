/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/


/// [CCP-455]
/// IE11 requires the following polyfills
import 'core-js/es7/array'; // Array.includes
import 'core-js/es7/object'; // Object.values  // Run `npm install --save classlist.js`.


/** Evergreen browsers require these. **/
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';


/** Old Edge browsers require the following to support Element.scrollTo method. **/
// import 'element-scroll-polyfill';


/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';

(window as any).global = window;

let pendingRequests = [];
function installWindowStopPolyfill() {
  Window.prototype.stop = function() {
    for (const request of pendingRequests) {
      request.abort();
    }

    pendingRequests.splice(0, pendingRequests.length);
  };

  const send = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function(body) {
    send.call(this, body);
    pendingRequests.push(this);

    // Note:
    //  In order to ensure that our list does not just keep
    //  growing, remove any requests that have already finished.
    pendingRequests = pendingRequests.filter(request => {
      return request.readyState !== XMLHttpRequest.DONE;
    });
  };
}

if (!window.stop) {
  installWindowStopPolyfill();
}
